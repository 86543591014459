document.addEventListener("DOMContentLoaded", function (event) {
  addModalToggleClickEvents();
});

export function addModalToggleClickEvents() {
  const modalButtons = document.querySelectorAll("[data-modal-button]");
  modalButtons.forEach((modalButton) => {
    modalButton.addEventListener('click', openModal);
  })

  const modalCloses = document.querySelectorAll("[data-modal-close]");
  modalCloses.forEach((modalClose) => {
    modalClose.addEventListener('click', hideModal);
  });
}

function openModal() {
  const modalId = this.dataset.modalButton;
  const modalContainer = document.querySelectorAll(`[data-modal-container="${modalId}"]`);
  modalContainer.forEach((modal) => {
    modal.classList.remove('d-none');
  });
}

function hideModal() {
  const modalId = this.dataset.modalClose;
  const modalContainer = document.querySelectorAll(`[data-modal-container="${modalId}"]`);
  modalContainer.forEach((modal) => {
    modal.classList.add('d-none');
  });
}
